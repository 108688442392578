import { ProductivityIcon } from "@/assets/icons";
import { motion } from "framer-motion";
import React from "react";
import RenderMeta from "./ratl/utility/RenderMeta";
import ReactIcon from "./ratl/utility/ReactIcon";
import { FaRegCirclePlay } from "react-icons/fa6"; //default icon change it
import clsx from "clsx";
import { IconType } from "react-icons";

type SectionTitleProps = {
  children?: React.ReactNode;
  className?: string; // Optional prop for custom class name
  sectionColor?: "green" | "red" | "purple";
  titleheader?: string;
  titleHighlight: string;
  titleDesc?: string;
  iconName?: IconType;
  showIcon?: boolean;
};

const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  className,
  sectionColor = "white",
  titleheader,
  titleHighlight,
  titleDesc,
  iconName = FaRegCirclePlay,
  showIcon = false,
}) => {
  const baseColor =
    {
      green: "rt-green-p1",
      red: "rt-red-p1",
      purple: "rt-purple-p1",
    }[sectionColor] || "";

  return (
    <div className="flex md:pl-[42px] space-x-3 md:space-x-10">
      <div className="flex flex-col items-center min-w-[22px]">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          viewport={{ once: false }}
          className="relative"
        >
          {showIcon ? <ReactIcon Icon={iconName} className="mb-3" /> : ""}
        </motion.div>
        <motion.div
          initial={{ height: 0 }}
          whileInView={{ height: "100%" }}
          transition={{ duration: 0.4, delay: 0.6 }}
          className={clsx(
            `from-transparent to-${baseColor}`,
            "h-full w-[3px] rounded-md bg-gradient-to-b"
          )}
        ></motion.div>
      </div>
      {/* title text */}
      <motion.div
        initial={{ opacity: 0, x: -30 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.6, type: "tween" }}
        className="md:w-10/12 lg:mb-24 mb-8"
      >
        <h2 className="rt-section-title-small">{titleheader}</h2>
        <h3
          className="text-[28px] md:text-[40px] max-md:leading-8 max-lg:leading-10 lg:text-5xl mb-7 font-medium text-white js-build-in-item build-in-slideX-left build-in-animate"
          style={{ transitionDelay: "300ms" }}
        >
          <div className="flex flex-col gap-4">
            <div className={clsx(`text-${baseColor}`)}>{titleHighlight}</div>
            <div>{titleDesc}</div>
          </div>
        </h3>
      </motion.div>
    </div>
  );
};

export default SectionTitle;
