import { useFloat } from "@/lib";
import CustomButton from "./CustonButton";
import {RT_STUDIO_RIL } from "@/data";

const NavButtons = () => {
  const { float, handleFloat } = useFloat([false, false]);
  return (
    <>
      {/* <CustomButton
        text={"Talk to Us"}
        animationRequired={true}
        bgType={"transparent"}
        onMouseEnter={() => handleFloat(true, 1)}
        onMouseLeave={() => handleFloat(false, 1)}
        controller={float[1]}
        onClick={() => (window.location.href = "/pricing#contact-form")}
        btnSize="small"
        isStudioBtn={false}
        showMobile={false}

      /> */}

      <CustomButton
        text={"Get Started for Free"}
        animationRequired={false}
        bgType={"solid"}
        onMouseEnter={() => handleFloat(true, 1)}
        onMouseLeave={() => handleFloat(false, 1)}
        controller={float[1]}
        onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
        btnSize="small"
        isStudioBtn={true}
        showMobile={false}
      />
    </>
  );
};

export default NavButtons;
