"use client"; // This marks it as a Client Component

import { useState, useEffect } from "react";
import { BsFillCaretUpSquareFill } from "react-icons/bs";

export default function GoUpButton() {
  const [isVisible, setIsVisible] = useState(false);
  const [iconSize, setIconSize] = useState(52); // Default to desktop size

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const updateIconSize = () => {
      // Update icon size based on window width
      if (window.innerWidth < 768) {
        setIconSize(32); // Mobile size
      } else {
        setIconSize(52); // Desktop size
      }
    };

    // Attach event listeners
    window.addEventListener("scroll", toggleVisibility);
    window.addEventListener("resize", updateIconSize);

    // Initial size check
    updateIconSize();

    return () => {
      // Cleanup event listeners
      window.removeEventListener("scroll", toggleVisibility);
      window.removeEventListener("resize", updateIconSize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-4 right-4 p-3 shadow-lg focus:outline-none ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300`}
      aria-label="Go to top"
    >
      <BsFillCaretUpSquareFill color="#6639ba" size={iconSize} />
    </button>
  );
}
