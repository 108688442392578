import { AnimatedAnchor } from "@/components/atoms";
import { InteractiveCard } from "@/components/widgets";

import { MarkIcon } from "@/assets/icons";

import type { BlockDataProps } from "@types";

const BlockData = ({
  controller,
  handleController,
  block,
  index,
}: BlockDataProps) => {
  return (
    <InteractiveCard
      backgroundColor="#939aff"
      direction=""
      left=""
    >
      <div className={block.className}>
        
        {block.imgComponent}
        <p className="rt-para mb-6 text-[#7d8590]">
          <span className="text-white font-medium">{block.title}</span>{" "}
          {block.description}
        </p>
        <div>
          <AnimatedAnchor
            onMouseEnter={() => handleController(true, index)}
            onMouseLeave={() => handleController(false, index)}
            href={block.href}
            title="Read"
            controller={controller[index]}
            isUnderline
          />
        </div>
        
      </div>
      
    </InteractiveCard>
  );
};




const CMakeData = () => {
  return (
    <li className="mb-6 flex items-center justify-between">
      <div className="flex items-center">
        {/* <MarkIcon /> */}
        
        <span className="font-medium text-white">Build</span>
      </div>
      <span className="text-[14px] text-[#7d8590]">1m 21s</span>
    </li>
  );
};

interface CMakeDataProps {
  label: string;
  desc: string;
  imageUrl: string;
}
const CMakeDataAgents: React.FC<CMakeDataProps> = ({ label, desc, imageUrl }) => {
  return (
    <li className="mb-6 flex items-center justify-between">
      <div className="flex items-center">
      {imageUrl && (
          <img 
            src={imageUrl} 
            alt={label} 
            className="w-16 h-16 mr-2 rounded-full object-cover" 
          />
        )}
        <div className="flex flex-col gap-2 ">
        <span className="font-medium text-white text-sm lg:text-base">{label}</span>
        <span className="text-sm text-[#7d8590] mr-4 text-right lg:hidden">{desc}</span>
        </div>


      </div>
      <span className="text-sm text-[#7d8590] mr-4 text-right hidden lg:block">{desc}</span>
    </li>
  );
};

export { BlockData, CMakeData, CMakeDataAgents };
