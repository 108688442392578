import { AnimatedAnchor } from "@/components/atoms";

import type { CollabDialogProps } from "@types";

const CollabDialog = ({
  title,
  desc,
  handleController,
  controller,
  index,
  text = "Enable ratlai Discussion",
  className = "md:flex flex-col flex-1 p-8 sm:p-10 lg:py-16 lg:pl-16 lg:pr-32",
  href,
}: CollabDialogProps) => {
  return (
    <div className={className}>
      <p className="rt-para mb-6 text-[#7d8590]">
        <span className="text-white font-semibold">{title}</span> {desc}
      </p>
      <div>
      </div>
    </div>
  );
};

export default CollabDialog;
