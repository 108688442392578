import { motion } from "framer-motion";

import { generateContainer, useFloat } from "@/lib";

import { CMakeData, BlockData } from "@/components/atoms";
import { InteractiveCard, BranchSec } from "@/components/widgets";

import { ProductivityIcon, SecurityIcon } from "@/assets/icons";

import { securityData } from "@/data";
import BriefcaseIcon from "@/assets/icons/BriefCaseIcon";
import RenderMeta from "../ratl/utility/RenderMeta";
import SectionFrame from "../SectionFrame";
import SectionTitle from "../SectionTitle";
import { BsBarChartSteps, BsFill6CircleFill } from "react-icons/bs";

const SectionUseCases = () => {
  const { float, handleFloat } = useFloat([false, false, false]);

  const container = generateContainer(1, 0.1, 0.4);

  const item = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { opacity: 1, scale: 1 },
  };
  return (
    <SectionFrame>
      <SectionTitle
        titleHighlight="See how ratl fits in"
        titleheader="Use Cases"
        titleDesc="Choose your use case and discover how ratl.ai can help your business"
        sectionColor="purple"
        showIcon={true}
        iconName={BsBarChartSteps}
      />

      <div className="flex flex-col md:flex-row gap-10">
        <BlockData
          controller={float}
          handleController={handleFloat}
          block={securityData.blocks[0]}
          index={1}
        />
        <BlockData
          controller={float}
          handleController={handleFloat}
          block={securityData.blocks[3]}
          index={2}
        />
        <BlockData
          controller={float}
          handleController={handleFloat}
          block={securityData.blocks[2]}
          index={1}
        />
      </div>
    </SectionFrame>
  );
};

export default SectionUseCases;
