"use client";
import { useFloat } from "@/lib";

import { GH_REPOSITORY_URL, RT_DEMO_CAL_URL, RT_LOGO_CTA, RT_LOGO_URL, RT_STUDIO_RIL } from "@/data";
import Logo from "@/components/ratl/utility/logo-nav";

import NavButtons from "@/components/ratl/utility/NavButtons";

const Navbar = () => {
  const { float: toggleNav, toggleFloat: setToggleNav } = useFloat(false);
  
  return (
    <nav className="  w-screen xl:px-20 md:px-8 sm:px-7 px-7 mx-auto mt-3 lg:mt-0">
      <div className="text-white bg-transparent py-5">
        <button className="hidden" />
        <div className="flex flex-row items-center w-full justify-between">
          <div className="flex lg:justify-between justify-center items-center max-lg:w-full">
            <div className="lg:hidden hidden">
              <a
                href="#"
                className="px-2 py-[6px] border-[1px] rounded-md hover:text-neutral-400"
              >
                Sign up
              </a>
            </div>
            <a href={RT_LOGO_CTA}>
              <Logo logoSrc={RT_LOGO_URL} />
            </a>
            <button
              type="button"
              onClick={() => setToggleNav()}
              className="lg:hidden cursor-pointer hidden"
            >
              <div
                className={`w-[23px] h-[2px] transition ease-in duration-150  bg-white ${
                  toggleNav
                    ? "rotate-45 mb-0  translate-y-[1px]"
                    : "rotate-0 mb-1"
                } `}
              ></div>
              <div
                className={`w-[23px] h-[2px] transition ease-in duration-150 bg-white ${
                  toggleNav ? "hidden mb-0" : "mb-1"
                } `}
              ></div>
              <div
                className={`w-[23px] h-[2px] transition ease-in duration-150 bg-white  ${
                  toggleNav ? "-rotate-45 mb-0" : "rotate-0"
                }`}
              ></div>
            </button>
          </div>
          <div className="flex flex-row gap-2 items-center">
          <nav className="mt-0 px-3 lg:px-6 text-[15.5px] mb-0 max-lg:py-10 max-lg:px-5 hidden lg:block">
            <ul className="flex lg:items-center lg:space-x-2 max-lg:flex-col max-lg:text-neutral-700 max-lg:text-xl max-lg:font-medium max-lg:space-y-6 max-lg:h-auto  overflow-y-auto">
              {/* <Product />
                  <Solutions />
                  <OpenSource /> */}
              <li>
                <a
                  href="/reflection2024"
                  className="max-lg:font-medium text-sm  flex items-center p-1 hover:text-neutral-300 text-rt-green-p1"
                >
                  {"[ Reflection 2024 ]"}
                </a>
              </li>
              <li>
                <a
                  href="/about-us"
                  className="max-lg:font-medium text-sm  flex items-center p-1 hover:text-neutral-300"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/pricing"
                  className="max-lg:font-medium text-sm  flex items-center p-1 hover:text-neutral-300"
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                  className="max-lg:font-medium text-sm   flex items-center p-1 hover:text-neutral-300"
                >
                  Blog
                </a>
              </li>
              {/* <li>
                <a
                  href="/pricing#contact-form"
                  className="max-lg:font-medium text-sm   flex items-center p-1 hover:text-neutral-300"
                >
                  Contact us
                </a>
              </li> */}
            </ul>
          </nav>
          <div
            className={` flex max-lg:absolute transition ease-in duration-200 top-20 z-40  ${
              toggleNav
                ? "max-lg:translate-x-0 max-lg:scale-100"
                : " max-lg:max-h-0 max-lg:scale-y-50 max-lg:-translate-y-1/4"
            }`}
          >
            <div className="flex max-lg:flex-col lg:items-center lg:justify-between max-lg:justify-between max-sm:w-10/12 max-sm:mx-auto sm:ml-auto max-lg:w-[320px] lg:w-full max-lg:max-h-[500px]  overflow-y-auto lg:mb-0 max-lg:bg-white max-lg:rounded-lg">
              <div className="lg:flex items-center max-lg:flex-col lg:px-0 px-3 mb-3 lg:mb-0 text-left lg:space-x-4">
                <NavButtons />
              </div>
            </div>
          </div>
          </div>


        </div>
      </div>
    </nav>
  );
};

export default Navbar;
