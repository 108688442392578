import { motion } from "framer-motion";

import { Picture, AnimatedAnchor, CMakeData } from "@/components/atoms";
import {
  IDE,
  BranchProd,
  InteractiveCard,
  Copilot,
} from "@/components/widgets";

import { useFloat, generateContainer } from "@/lib";

import { ProductivityIcon } from "@/assets/icons";
import VideoBox from "../widgets/VideoBox";
import { useEffect, useState } from "react";
import { CMakeDataAgents } from "../atoms/AtomsSec";
import RenderMeta from "../ratl/utility/RenderMeta";
import SectionFrame from "../SectionFrame";
import { FaRegCirclePlay } from "react-icons/fa6";
import SectionTitle from "../SectionTitle";

const SectionAgents = () => {
  const { float, handleFloat } = useFloat([false, false]);

  const container = generateContainer(1, 0.1, 0.4);

  const item = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { opacity: 1, scale: 1 },
  };

  return (
    <SectionFrame>
      <SectionTitle
        sectionColor="green"
        titleHighlight="Accelerate high-quality software delivery"
        titleheader="Introducing AI Agents for Testing"
        titleDesc="Here is Quick Demo"
        iconName={FaRegCirclePlay}
        showIcon={true}
      />
      <VideoBox />
      <SectionTitle
        sectionColor="green"
        titleHighlight="Meet the ratl.ai Agents"
        showIcon={false}
      />

      <InteractiveCard backgroundColor="#939aff" direction="" left="0">
        <div className="flex w-10/12 flex-col mx-auto">
          <motion.div
            variants={container}
            initial="hidden"
            whileInView="show"
            className="relative flex text-[17px] flex-col md:flex-row lg:my-10 flex-1 justify-between lg:w-10/12 w-full lg:mx-auto mx-0 md:gap-10"
          >
            <motion.div
              variants={item}
              transition={{ type: "tween" }}
              className="animate w-full"
            >
              <p className="rt-para mb-6 lg:mt-0 mt-6 text-[#7d8590]">
                <span className="text-white font-medium">
                  ratl API testing agents
                </span>
              </p>
              <div
                className="p-2 md:mt-8 mb-10 box-shadow-mktg-xl rounded-md"
                style={{ background: "rgba(235, 245, 255, 0.2)" }}
              >
                <ul className="-mb-6">
                  <CMakeDataAgents
                    label={"INGA"}
                    desc={"Testing Expert"}
                    imageUrl={"/ratl/agents/inga-face.png"}
                  />
                </ul>
              </div>

              <div
                className="p-2 md:mt-8 mb-10 box-shadow-mktg-xl rounded-md"
                style={{ background: "rgba(235, 245, 255, 0.2)" }}
              >
                <ul className="-mb-6">
                  <CMakeDataAgents
                    label={"CHASE"}
                    desc={"Load & Performance Expert"}
                    imageUrl={"/ratl/agents/chase-face.png"}
                  />
                </ul>
              </div>

              <div
                className="p-2 md:mt-8 mb-10 box-shadow-mktg-xl rounded-md"
                style={{ background: "rgba(235, 245, 255, 0.2)" }}
              >
                <ul className="-mb-6">
                  <CMakeDataAgents
                    label={"CASEY"}
                    desc={"Accessibility Expert"}
                    imageUrl={"/ratl/agents/casey-face.png"}
                  />
                </ul>
              </div>
              <p className="rt-para mb-6 text-[#7d8590]">
                Autonomously generate and execute API tests, including
                functional, integration, and load testing, while seamlessly
                integrating with CI/CD pipelines
              </p>
            </motion.div>
            <motion.div
              variants={item}
              transition={{ type: "tween" }}
              className="hidden absolute md:top-12   lg:left-[47%] md:left-[46%] max-md:top-[88px] z-[1]  items-center max-md:rotate-90"
            >
              <div
                className="inline-block rounded-full p-1 bg-[#6e7681]"
                style={{ boxShadow: "0 0 0 2px #444D56" }}
              ></div>
              <div
                style={{ width: "37px", height: "2px", background: "#D1D5DA" }}
              ></div>
              <div
                className="inline-block rounded-full p-1 bg-[#6e7681]"
                style={{ boxShadow: "0 0 0 2px #444D56" }}
              ></div>
            </motion.div>
            <motion.div
              variants={item}
              transition={{ type: "tween" }}
              className="animate w-full"
            >
              <p className="rt-para mb-6 text-[#7d8590]">
                <span className="text-white font-medium">
                  ratl UI testing agents (upcoming)
                </span>
              </p>
              <div
                className="p-2 md:mt-8 mb-10 box-shadow-mktg-xl rounded-md"
                style={{ background: "rgba(235, 245, 255, 0.2)" }}
              >
                <ul className="-mb-6">
                  <CMakeDataAgents
                    label={"WEBSHOTS"}
                    desc={"Web Apps"}
                    imageUrl={"/ratl/agents/web-face.png"}
                  />
                </ul>
              </div>

              <div
                className="p-2 md:mt-8 mb-10 box-shadow-mktg-xl rounded-md"
                style={{ background: "rgba(235, 245, 255, 0.2)" }}
              >
                <ul className="-mb-6">
                  <CMakeDataAgents
                    label={"MOBSHOTS"}
                    desc={"Mobile Apps"}
                    imageUrl={"/ratl/agents/mob-face.png"}
                  />
                </ul>
              </div>

              <div
                className="p-2 md:mt-8 mb-10 box-shadow-mktg-xl rounded-md"
                style={{ background: "rgba(235, 245, 255, 0.2)" }}
              >
                <ul className="-mb-6">
                  <CMakeDataAgents
                    label={"DESKSHOTS"}
                    desc={"Computer Interface"}
                    imageUrl={"/ratl/agents/casey-face.png"}
                  />
                </ul>
              </div>
              <p className="rt-para mb-6 text-[#7d8590]"></p>
            </motion.div>
          </motion.div>
        </div>
      </InteractiveCard>

      <SectionTitle
        titleHighlight={"Redefining the SDLC"}
        titleheader="Embracing AI-Native Practices"
        showIcon={false}
        sectionColor="green"
      />

      <div className="w-full">
        <InteractiveCard
          backgroundColor="#7ee787"
          direction="flex-col"
          left="-400px"
        >
          <div className="overflow-hidden rounded-s-lg flex items-center justify-center">
            <Picture
              className="w-full h-auto hidden lg:block"
              size={[1208, 764]}
              src="/ratl/ratl-sdlc-1.png"
              alt={""}
            />
            <Picture
              className="w-full h-auto md:hidden"
              size={[1208, 764]}
              src="/ratl/ratl-sdlc-2.png"
              alt={""}
            />
          </div>
        </InteractiveCard>
      </div>

      <BranchProd />

      <div className="relative z-[1]">
        <Copilot />
      </div>

      <SectionTitle titleHighlight={""} sectionColor="red" showIcon={false} />
    </SectionFrame>
  );
};

export default SectionAgents;
