import { API_BASE_URL, UI_BASE_URL } from "@/data";
import { useRef, useState } from "react";
import CustomButton from "../ratl/utility/CustonButton";
import { useFloat } from "@/lib";

const StartTesting = () => {
  const { float, handleFloat } = useFloat([false, false]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [isValidCurl, setIsValidCurl] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const handleSampleCurlClick = () => {
    if (textareaRef.current) {
      textareaRef.current.value =
        "curl -X 'GET' 'https://reqres.in/api/users' -H 'accept: application/json'";
      setIsInputEmpty(false);
      setIsValidCurl(true);
    }
  };

  const preHandleClickMobile = () => {
    setIsMobile(true);
    if (isMobile) {
      const inputValue =
        "curl -X 'GET' 'https://reqres.in/api/users' -H 'accept: application/json'";
      handleStartTestingClick(inputValue);
    }
  };

  const preHandleClick = () => {
    if (!isInputEmpty && isValidCurl && textareaRef.current && !isMobile) {
      const inputValue = textareaRef.current.value.trim();
      handleStartTestingClick(inputValue);
    }
  };

  const handleStartTestingClick = async (input: string) => {
    try {
      const response = await fetch(
        `/api/guestSesion`,
        // `${API_BASE_URL}/tempest/api/v2/public/guest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: input }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to establish guest session");
      }

      const result = await response.json();
      const sessionId = result.guestSessionId;

      if (sessionId) {
        window.open(`${UI_BASE_URL}?id=${sessionId}`, "_blank");
      } else {
        console.log("Failed to retrieve session ID")
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleInputChange = async () => {
    if (textareaRef.current) {
      const inputValue = textareaRef.current.value.trim();

      // Restrict excessively large inputs
      if (inputValue.length > 5000) {
        alert("Input is too large. Please provide a smaller cURL command.");
        setIsValidCurl(false);
        return;
      }

      setIsInputEmpty(inputValue === "");

      // Call the API to validate the cURL command
      try {
        const response = await fetch("/api/validate-curl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ curlCommand: inputValue }),
        });

        if (!response.ok) {
          throw new Error("Invalid cURL");
        }

        const result = await response.json();
        setIsValidCurl(result.valid);
      } catch (error) {
        console.error("Validation error:", error);
        setIsValidCurl(false);
      }
    }
  };

  return (
    <>
      {/* Mobile View */}
      <div className="relative md:top-1 mb-5 lg:hidden md:w-auto">
        <CustomButton
          text={"Try with Sample cURL"}
          animationRequired={true}
          bgType={"transparent"}
          onMouseEnter={() => handleFloat(true, 1)}
          onMouseLeave={() => handleFloat(false, 1)}
          controller={float[1]}
          image="/ratl/ratl-mascot-2.png"
          onClick={preHandleClickMobile}
          btnSize="large"
          isStudioBtn={false}
          showMobile={true}
        />
      </div>

      {/* Desktop View */}
      <div className="bg-gray-900 w-auto rounded-2xl px-6 pt-6 pb-2 mr-32 shadow-rt-purple-p1 shadow-rt-all-sides relative hidden lg:block my-14 border-rt-purple-p1 border-rt-regular">
        <textarea
          id="typewriter-input"
          rows={4}
          ref={textareaRef}
          onChange={handleInputChange}
          className="w-full bg-transparent border-none text-gray-300 placeholder-gray-500 text-sm outline-none mb-4 mx-auto resize-none overflow-y-auto"
          placeholder="Enter your cURL command here"
        />
        <div className="flex flex-row justify-between items-end gap-2 ">
          <div className="flex flex-wrap justify-start gap-3">
            <button
              onClick={handleSampleCurlClick}
              className="bg-gray-700 text-white px-4 py-1 rounded-md text-xs flex items-center hover:bg-gray-600"
            >
              🤖 Try Sample cURL
            </button>
            <button
              disabled
              className="bg-gray-800 text-gray-500 px-4 py-1 rounded-md text-xs flex items-center cursor-not-allowed relative group"
            >
              🤖 Try Sample API Collection
              <span className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-gray-500 text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                Upgrade
              </span>
            </button>
            <button
              disabled
              className="bg-gray-800 text-gray-500 px-4 py-1 rounded-md text-xs flex items-center cursor-not-allowed relative group"
            >
              📎 Upload
              <span className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-gray-500 text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                Upgrade
              </span>
            </button>
          </div>

          <div className="flex flex-wrap gap-3">
            
            <div className="flex items-center justify-between">
              <button
                onClick={preHandleClick}
                disabled={isInputEmpty || !isValidCurl}
                className={`relative px-4 py-2 rounded-md text-sm flex items-center transition ${
                  isInputEmpty || !isValidCurl
                    ? "text-gray-500 bg-gray-800 cursor-not-allowed"
                    : "text-white hover:bg-gray-800 bg-[linear-gradient(90deg,#6e40c9_0%,#afa2f9_100%)] border-[#6e40c9] border-[1.5px]"
                } group`}
              >
                Start Testing
                {(isInputEmpty || !isValidCurl) && (
                  <span className=" w-full absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-gray-500 text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                    {isInputEmpty ? "Enter cURL" : "Invalid cURL"}
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartTesting;
