/* eslint-disable @next/next/no-img-element */
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

import { Picture } from "@/components/atoms";
import {
  BranchCollab,
  InteractiveCard,
  CollabDialog,
} from "@/components/widgets";

import { useFloat, generateContainer } from "@/lib";
import { CollaborationIcon, HeartIcon } from "@/assets/icons";

import { collaborationData } from "@/data";
import { BRANCH_DATA } from "../widgets/branchCollaborationData";
import RenderMeta from "../ratl/utility/RenderMeta";
import ContactForm from "../widgets/ContactForm";
import SectionFrame from "../SectionFrame";
import SectionTitle from "../SectionTitle";
import { FaNetworkWired } from "react-icons/fa6";

const SectionHowitWorks = () => {
  const { float, handleFloat } = useFloat([false, false, false]);

  const container = generateContainer(1, 0.1, 0.1);

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };
  return (
    <SectionFrame>

      <SectionTitle 
      titleHighlight={"Supercharge Testing Journey"} 
      titleheader="How it Works?"
      titleDesc="New to AI or a pro? ratl Studio offers unlimited workspaces with AI-assisted and autonomous testing by powerful AI agents. Explore now!"
      sectionColor="red"
      iconName={FaNetworkWired}
      showIcon={true}
      />
      <div>
        {BRANCH_DATA.map((data, index) => (
          <BranchCollab key={index} text={data.text} images={data.images} />
        ))}
      </div>

      <SectionTitle titleHighlight={"3 simple steps"}
      sectionColor="red"
      titleDesc="Your API test suite is complete with functional, 
            integration, and load/performance testing. Test cases are automatically generated,
            coded, and executed. 
            Get a fully functional API testing setup with results in minutes!"
            showIcon={false} />
      
      
      
     
      <InteractiveCard backgroundColor="#ffa28b" direction="" left="">
        <CollabDialog
          title="ratl.ai "
          desc="integrates seamlessly with cutting-edge AI-powered autonomous development platforms such as Loveable.dev, Replit, and Devin. This integration enhances the software development lifecycle, bringing modern automation, intelligent workflows, and streamlined delivery pipelines to the forefront of innovation"
          handleController={handleFloat}
          controller={float}
          index={2}
          text="Talk to Us"
          className="md:flex flex-col md:space-y-20 flex-1 py-20 p-10 justify-between" href={""}        />
        <div className="overflow-hidden rounded-s-lg z-[1] flex-1">
          <motion.div
            variants={container}
            initial="hidden"
            whileInView="show"
            className="flex flex-wrap w-full relative -top-[120px] -rotate-12 -right-6 -mt-6  p-6 max-h-[480px]"
          >
            {collaborationData.sponsors.map((sponsor) => (
              <motion.div
                key={uuidv4()}
                variants={item}
                transition={{ type: "tween " }}
                className="w-1/3"
              >
                <a
                  href={`https://github.com/${sponsor.name}`}
                  target="_blank"
                  className="rounded-md bg-[#161b22] border-[0.5px] border-[#30363d] flex flex-col items-center m-2 p-6"
                >
                  <Picture
                    src={sponsor.src}
                    size={[96]}
                    className="rounded-md" alt={""}                  />
                  <div className="text-[#7d8590] my-2">{sponsor.name}</div>
                  <button type="button" className="bg-[#21262d] rounded-md">
                    <span className="flex items-center justify-between px-2 py-1 space-x-2">
                      <CollaborationIcon />
                      {/* <span className="Button-label text-[#c9d1d9]">
                        Sponsor
                      </span> */}
                    </span>
                  </button>
                </a>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </InteractiveCard>

      <ContactForm />
      
      <SectionTitle titleHighlight="" sectionColor="purple" showIcon={false} />
   
    </SectionFrame>
  );
};

export default SectionHowitWorks;
