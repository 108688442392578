"use client";

import { useEffect, useState } from "react";

import { AnimatedAnchor } from "@/components/atoms";

import { DownArrowIcon, CloseIcon, GithubIcon } from "@/assets/icons";

import { navigationData, GH_REPOSITORY_URL } from "@/data";

import { StickyState, InteractionState } from "@types";
import NavButtons from "@/components/ratl/utility/NavButtons";
import Logo from "@/components/ratl/utility/logo-nav";

const StickyNavbar = () => {
  const [stickyState, setStickyState] = useState<StickyState>({
    isSticky: false,
    isFixed: false,
  });

  const [interactionState, setInteractionState] = useState<InteractionState>({
    hovered: "",
    showMobileNav: false,
  });

  const [activeSection, setActiveSection] = useState("");

  const handleMouseEnter = (id: string) => {
    updateInteractionState({ hovered: id });
  };

  const handleMouseLeave = () => {
    updateInteractionState({ hovered: "" });
  };

  const updateStickyState = (newState: Partial<StickyState>): void => {
    setStickyState((prevState) => ({ ...prevState, ...newState }));
  };

  const updateInteractionState = (
    newState: Partial<InteractionState>
  ): void => {
    setInteractionState((prevState) => ({ ...prevState, ...newState }));
  };

  const handleScroll = () => {
    const navElement = document.getElementById("nav");
    const topCoordinate = navElement?.getBoundingClientRect().top;

    if (topCoordinate) {
      updateStickyState({
        isSticky: topCoordinate < 0,
        isFixed: topCoordinate < -20,
      });
    }

    navigationData.items.forEach((item) => {
      const sectionEl = document.getElementById(item.id);
      let topSection = (sectionEl?.getBoundingClientRect().top ?? 0) - 100;
      if (topSection) topSection -= 100;
      setActiveSection((prevActiveSection) =>
        topSection && topSection < 10 ? item.id : prevActiveSection
      );
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="nav"
      className={`absolute h-[100px] z-[3] transition ease-in duration-150  ${
        stickyState.isSticky ? "visible" : "invisible"
      }`}
    >
      <div
        className={`w-screen ${
          stickyState.isFixed ? "fixed" : "sticky"
        }  py-2 bg-[#0d1117] shadow-slate-950 shadow-md top-0 `}
      >
        <div className="max-w-[1280px] mx-auto pb-2 lg:pb-0 pt-0 flex lg:px-0 px-12 items-center max-lg:flex-col relative ">
          <button
            onClick={() =>
              updateInteractionState({
                showMobileNav: !interactionState.showMobileNav,
              })
            }
            className="lg:hidden absolute right-12 top-4"
          >
            <DownArrowIcon show={interactionState.showMobileNav} />
            <CloseIcon show={interactionState.showMobileNav} />
          </button>

          <div
            className={`lg:ml-0 flex items-center lg:space-x-5 max-lg:space-y-3 max-lg:flex-col max-lg:w-full max-lg:mt-5 ${
              !interactionState.showMobileNav && "max-lg:hidden"
            }`}
          >
            <a href={"#hero-home-page"}>
              <img
                src="/ratl/ratl-logo-circle-white-bg-new.png"
                alt="ratl-logo"
                className=""
                width={"50px"}
                height={"auto"}
              />
            </a>
          </div>

          <div className="flex lg:flex-row flex-col text-[16px] justify-between max-lg:w-full flex-auto lg:space-x-4 lg:ml-6  text-white">
            <div className="flex lg:flex-row lg:gap-6 items-center flex-col gap-1 lg:my-0 my-0 ">
              {navigationData.items.map(({ id, label, url }) => {
                const isActive = activeSection === id;

                return (
                  <a
                    key={`sticky-navbar-item-${label}-${id}`}
                    href={url}
                    onClick={() =>
                      updateInteractionState({ showMobileNav: false })
                    }
                    onMouseEnter={() => handleMouseEnter(id)}
                    onMouseLeave={handleMouseLeave}
                    className={`max-lg:pt-2 lg:hover:text-rt-purple-p2 ${
                      interactionState.showMobileNav || isActive
                        ? "max-lg:pb-0"
                        : "max-lg:hidden"
                    }${id==="reflection-2024"?" lg:text-rt-green-p1 hidden lg:block":""}`}
                  >
                    {label}
                    <div
                      className={`w-10/12 max-lg:hidden mx-auto mt-1 h-[1px] scale-0 transition ease-in duration-200 ${
                        isActive &&
                        interactionState.hovered !== id &&
                        "scale-100 bg-white"
                      } ${
                        interactionState.hovered === id &&
                        "scale-100 bg-rt-purple-p2"
                      }`}
                    />
                  </a>
                );
              })}
              <a
                className="text-base hover:text-rt-purple-p2 text-rt-green-p1 hover:underline lg:hidden"
                href="/reflection2024"
              >
                {"Reflection 2024"}
              </a>
            </div>
            <div className="lg:flex flex-row items-center gap-6 md:hidden">
              <NavButtons />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyNavbar;
