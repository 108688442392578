"use client";
import { StickyNavbar } from "@/components/layout";
import { Earth, Globe } from "@/components/widgets";
import SectionAgents from "@/components/sections/SectionAgents";
import SectionHowitWorks from "@/components/sections/SectionHowItWorks";
import SectionUseCases from "@/components/sections/SectionUseCases";
import Hero from "@/components/sections/Hero";
import { navigationData } from "@/data";
import { v4 as uuidv4 } from "uuid";
import Picture from "@/components/ratl/utility/Picture";

/* eslint-disable @next/next/no-img-element */
export default function NotFound() {
  return (
    <div className="overflow-hidden h-screen relative top-8 ">
      
      <div className="overflow-hidden relative">
      
      <div className={`relative z-[2] max-sm:hidden`}>
        <Earth />
      </div>
      <div className={`relative z-[2] sm:hidden`}>
        <Earth offset={[0, 0]} />
      </div>
      <Picture
        className="absolute bottom-0 left-0 object-cover w-full h-full"
        // size={[801, 807]}
        size={[958, 484]}
        src="/home-campaign/footer-galaxy.jpg"
      />
      <div className="flex flex-col items-center justify-center relative z-[2] md:mt-[-200px] mt-[-120px]">
        <Picture
          src="/ratl/ratl-mascot-4.webp"
          className="md:w-[400px] w-2/3 h[403] object-cover pointer-events-none"
          size={[801, 807]}
          loading="eager"
          decoding="auto"
        />
        <div className="text-white text-center px-4 mt-4 text-xl">
        <span className="text-rt-green-p1">Lost in the test lab!</span> Let’s head back to safer ground
      </div>
      </div>
      
      
    </div>
    </div>
  );
}
