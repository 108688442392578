import { motion } from "framer-motion";
import { PythonIcon, JavaScriptIcon, GoIcon } from "@/assets/icons";
import { FaXTwitter, FaGithub, FaYoutube, FaLinkedin } from "react-icons/fa6";
import SectionAgents from "@/components/sections/SectionAgents";
import SectionHowitWorks from "@/components/sections/SectionHowItWorks";
import SectionUseCases from "@/components/sections/SectionUseCases";


export const GH_USER_URL = "https://github.com/ladunjexa";
export const GH_REPOSITORY_URL = `${GH_USER_URL}/GitHub-Webpage`;


//RATL CONFIGS
export const RT_BASE_URL = "";
export const RT_LOGO_URL = `${RT_BASE_URL}/#`;
export const RT_HERO_TITLE_L1 =
  "AI agents for Autonomous Testing & Software Delivery";
export const RT_STUDIO_RIL = "https://studio.ratl.ai";
export const RT_DEMO_CAL_URL =
  "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3_6e3xIb4437kfAq4M12sflPChZfqXT6Uj1Dgzj7OJkZkmxC99Ob22WtbAJvl0Bo6U0EoW5f3X?gv=true";

export const RT_HERO_BANNER = `${RT_BASE_URL}/ratl/hero/hero-bg2.webp`;
export const RT_INTRO_VIDEO_CDN = "https://cdn.pixelbin.io/v2/patient-sunset-d145ab/original/ratl_intro.mp4";
export const RT_INTRO_VIDEO_YT = "EE57T8D_jeI";

const footerData = {
  sections: [
    {
      title: "Product",
      items: [
        "Features",
        "Security",
        "Team",
        "Enterprise",
        "Customer stories",
        "The ReadME Project",
        "Pricing",
        "Resources",
        "Roadmap",
        "Compare GitHub",
      ],
    },
    {
      title: "Platform",
      items: ["Developer API", "Partners", "Electron", "GitHub Desktop"],
    },
    {
      title: "Support",
      items: [
        "Docs",
        "Community Forum",
        "Professional Services",
        "Premium Support",
        "Skills",
        "Status",
        "Contact GitHub",
      ],
    },
    {
      title: "Company",
      items: [
        "About",
        "Blog",
        "Careers",
        "Press",
        "Inclusion",
        "Social Impact",
        "Shop",
      ],
    },
  ],

  ratlfooterlinks: [
    {
      title: "About Us",
      href: "/about-us",
    },
    {
      title: "Pricing",
      href: "/pricing",
    },
    {
      title: "Blog",
      href: "/blog",
    },
    {
      title: "Contact Us",
      href: "/pricing#contact-form",
    },
  ],

  items: [
    {
      title: "Terms of Use",
      href: "/terms-of-use"
    },
    {
      title: "Privacy Policy",
      href: "/privacy-policy"
    }
  ],
  socials: [
    {
      title: "Twitter",
      icon: <FaXTwitter color="#7D8590" size={24} />,
      href: "https://x.com/ratlaitech",
    },
    {
      title: "LinkedIn",
      icon: <FaLinkedin color="#7D8590" size={24} />,
      href: "https://www.linkedin.com/company/ratlai/",
    },
    {
      title: "YouTube",
      icon: <FaYoutube color="#7D8590" size={24} />,
      href: "https://www.youtube.com/@ratlaitech",
    },
    {
      title: "GitHub",
      icon: <FaGithub color="#7D8590" size={24} />,
      href: "https://github.com/ratlai",
    },
  ],
};

const navigationData = {
  productItems: [
    {
      main: "Documentation",
      path: "M3.75 2h3.5a.75.75 0 0 1 0 1.5h-3.5a.25.25 0 0 0-.25.25v8.5c0 .138.112.25.25.25h8.5a.25.25 0 0 0 .25-.25v-3.5a.75.75 0 0 1 1.5 0v3.5A1.75 1.75 0 0 1 12.25 14h-8.5A1.75 1.75 0 0 1 2 12.25v-8.5C2 2.784 2.784 2 3.75 2Zm6.854-1h4.146a.25.25 0 0 1 .25.25v4.146a.25.25 0 0 1-.427.177L13.03 4.03 9.28 7.78a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042l3.75-3.75-1.543-1.543A.25.25 0 0 1 10.604 1Z",
    },
    {
      main: "GitHub skills",
      path: "M3.75 2h3.5a.75.75 0 0 1 0 1.5h-3.5a.25.25 0 0 0-.25.25v8.5c0 .138.112.25.25.25h8.5a.25.25 0 0 0 .25-.25v-3.5a.75.75 0 0 1 1.5 0v3.5A1.75 1.75 0 0 1 12.25 14h-8.5A1.75 1.75 0 0 1 2 12.25v-8.5C2 2.784 2.784 2 3.75 2Zm6.854-1h4.146a.25.25 0 0 1 .25.25v4.146a.25.25 0 0 1-.427.177L13.03 4.03 9.28 7.78a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042l3.75-3.75-1.543-1.543A.25.25 0 0 1 10.604 1Z",
    },
    {
      main: "Blog",
      path: "M3.75 2h3.5a.75.75 0 0 1 0 1.5h-3.5a.25.25 0 0 0-.25.25v8.5c0 .138.112.25.25.25h8.5a.25.25 0 0 0 .25-.25v-3.5a.75.75 0 0 1 1.5 0v3.5A1.75 1.75 0 0 1 12.25 14h-8.5A1.75 1.75 0 0 1 2 12.25v-8.5C2 2.784 2.784 2 3.75 2Zm6.854-1h4.146a.25.25 0 0 1 .25.25v4.146a.25.25 0 0 1-.427.177L13.03 4.03 9.28 7.78a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042l3.75-3.75-1.543-1.543A.25.25 0 0 1 10.604 1Z",
    },
    {
      main: "Action",
      submain: "Automate any workflow",
      path2: "",
      path: "M1 3a2 2 0 0 1 2-2h6.5a2 2 0 0 1 2 2v6.5a2 2 0 0 1-2 2H7v4.063C7 16.355 7.644 17 8.438 17H12.5v-2.5a2 2 0 0 1 2-2H21a2 2 0 0 1 2 2V21a2 2 0 0 1-2 2h-6.5a2 2 0 0 1-2-2v-2.5H8.437A2.939 2.939 0 0 1 5.5 15.562V11.5H3a2 2 0 0 1-2-2Zm2-.5a.5.5 0 0 0-.5.5v6.5a.5.5 0 0 0 .5.5h6.5a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5ZM14.5 14a.5.5 0 0 0-.5.5V21a.5.5 0 0 0 .5.5H21a.5.5 0 0 0 .5-.5v-6.5a.5.5 0 0 0-.5-.5Z",
    },
    {
      main: "Packages",
      submain: "Host and manage packages",
      path2: "",
      path: "M12.876.64V.639l8.25 4.763c.541.313.875.89.875 1.515v9.525a1.75 1.75 0 0 1-.875 1.516l-8.25 4.762a1.748 1.748 0 0 1-1.75 0l-8.25-4.763a1.75 1.75 0 0 1-.875-1.515V6.917c0-.625.334-1.202.875-1.515L11.126.64a1.748 1.748 0 0 1 1.75 0Zm-1 1.298L4.251 6.34l7.75 4.474 7.75-4.474-7.625-4.402a.248.248 0 0 0-.25 0Zm.875 19.123 7.625-4.402a.25.25 0 0 0 .125-.216V7.639l-7.75 4.474ZM3.501 7.64v8.803c0 .09.048.172.125.216l7.625 4.402v-8.947Z",
    },
    {
      main: "Security",
      submain: "Find and fix vulnerability",
      path2:
        "M16.53 9.78a.75.75 0 0 0-1.06-1.06L11 13.19l-1.97-1.97a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0l5-5Z",

      path: "m12.54.637 8.25 2.675A1.75 1.75 0 0 1 22 4.976V10c0 6.19-3.771 10.704-9.401 12.83a1.704 1.704 0 0 1-1.198 0C5.77 20.705 2 16.19 2 10V4.976c0-.758.489-1.43 1.21-1.664L11.46.637a1.748 1.748 0 0 1 1.08 0Zm-.617 1.426-8.25 2.676a.249.249 0 0 0-.173.237V10c0 5.46 3.28 9.483 8.43 11.426a.199.199 0 0 0 .14 0C17.22 19.483 20.5 15.461 20.5 10V4.976a.25.25 0 0 0-.173-.237l-8.25-2.676a.253.253 0 0 0-.154 0Z",
    },
    {
      main: "CodeSpace",
      submain: "Instant dev environments",
      path2:
        "M10 17.75a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1-.75-.75Zm-4 0a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z",
      path: "M3.5 3.75C3.5 2.784 4.284 2 5.25 2h13.5c.966 0 1.75.784 1.75 1.75v7.5A1.75 1.75 0 0 1 18.75 13H5.25a1.75 1.75 0 0 1-1.75-1.75Zm-2 12c0-.966.784-1.75 1.75-1.75h17.5c.966 0 1.75.784 1.75 1.75v4a1.75 1.75 0 0 1-1.75 1.75H3.25a1.75 1.75 0 0 1-1.75-1.75ZM5.25 3.5a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h13.5a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25Zm-2 12a.25.25 0 0 0-.25.25v4c0 .138.112.25.25.25h17.5a.25.25 0 0 0 .25-.25v-4a.25.25 0 0 0-.25-.25Z",
    },
    {
      main: "Copilot",
      submain: "Write better code with AI",
      path2:
        "M12 2c2.214 0 4.248.657 5.747 1.756.136.099.268.204.397.312.584.235 1.077.546 1.474.952.85.869 1.132 2.037 1.132 3.368 0 .368-.014.733-.052 1.086l.633 1.478.043.022A4.75 4.75 0 0 1 24 15.222v1.028c0 .529-.309.987-.565 1.293-.28.336-.636.653-.966.918a13.84 13.84 0 0 1-1.299.911l-.024.015-.006.004-.039.025c-.223.135-.45.264-.68.386-.46.245-1.122.571-1.941.895C16.845 21.344 14.561 22 12 22c-2.561 0-4.845-.656-6.479-1.303a19.046 19.046 0 0 1-1.942-.894 14.081 14.081 0 0 1-.535-.3l-.144-.087-.04-.025-.006-.004-.024-.015a13.16 13.16 0 0 1-1.299-.911 6.913 6.913 0 0 1-.967-.918C.31 17.237 0 16.779 0 16.25v-1.028a4.75 4.75 0 0 1 2.626-4.248l.043-.022.633-1.478a10.195 10.195 0 0 1-.052-1.086c0-1.331.282-2.498 1.132-3.368.397-.406.89-.717 1.474-.952.129-.108.261-.213.397-.312C7.752 2.657 9.786 2 12 2Zm-8 9.654v6.669a17.59 17.59 0 0 0 2.073.98C7.595 19.906 9.686 20.5 12 20.5c2.314 0 4.405-.594 5.927-1.197a17.59 17.59 0 0 0 2.073-.98v-6.669l-.038-.09c-.046.061-.095.12-.145.177-.793.9-2.057 1.259-3.782 1.259-1.59 0-2.738-.544-3.508-1.492a4.323 4.323 0 0 1-.355-.508h-.344a4.323 4.323 0 0 1-.355.508C10.704 12.456 9.555 13 7.965 13c-1.725 0-2.989-.359-3.782-1.259a3.026 3.026 0 0 1-.145-.177Zm6.309-1.092c.445-.547.708-1.334.851-2.301.057-.357.087-.718.09-1.079v-.031c-.001-.762-.166-1.26-.43-1.568l-.008-.01c-.341-.391-1.046-.689-2.533-.529-1.505.163-2.347.537-2.824 1.024-.462.473-.705 1.18-.705 2.32 0 .605.044 1.087.135 1.472.092.384.231.672.423.89.365.413 1.084.75 2.657.75.91 0 1.527-.223 1.964-.564.14-.11.268-.235.38-.374Zm2.504-2.497c.136 1.057.403 1.913.878 2.497.442.545 1.134.938 2.344.938 1.573 0 2.292-.337 2.657-.751.384-.435.558-1.151.558-2.361 0-1.14-.243-1.847-.705-2.319-.477-.488-1.318-.862-2.824-1.025-1.487-.161-2.192.139-2.533.529-.268.308-.437.808-.438 1.578v.02c.002.299.023.598.063.894Z",
      path: "M9.75 14a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-1.5 0v-2.5a.75.75 0 0 1 .75-.75Zm4.5 0a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-1.5 0v-2.5a.75.75 0 0 1 .75-.75Z",
    },
    {
      main: "Code review",
      submain: "Manage code changes",
      path2:
        "M10.3 6.74a.75.75 0 0 1-.04 1.06l-2.908 2.7 2.908 2.7a.75.75 0 1 1-1.02 1.1l-3.5-3.25a.75.75 0 0 1 0-1.1l3.5-3.25a.75.75 0 0 1 1.06.04Zm3.44 1.06a.75.75 0 1 1 1.02-1.1l3.5 3.25a.75.75 0 0 1 0 1.1l-3.5 3.25a.75.75 0 1 1-1.02-1.1l2.908-2.7-2.908-2.7Z",
      path: "M1.5 4.25c0-.966.784-1.75 1.75-1.75h17.5c.966 0 1.75.784 1.75 1.75v12.5a1.75 1.75 0 0 1-1.75 1.75h-9.69l-3.573 3.573A1.458 1.458 0 0 1 5 21.043V18.5H3.25a1.75 1.75 0 0 1-1.75-1.75ZM3.25 4a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h2.5a.75.75 0 0 1 .75.75v3.19l3.72-3.72a.749.749 0 0 1 .53-.22h10a.25.25 0 0 0 .25-.25V4.25a.25.25 0 0 0-.25-.25Z",
    },
    {
      main: "Issues",
      submain: "Plan and track issues",
      path: "M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1ZM2.5 12a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5A9.5 9.5 0 0 0 12 2.5 9.5 9.5 0 0 0 2.5 12Zm9.5 2a2 2 0 1 1-.001-3.999A2 2 0 0 1 12 14Z",
      path2: "",
    },
    {
      main: "Discussions",
      submain: "Collaborate outside of code",
      path: "M1.75 1h12.5c.966 0 1.75.784 1.75 1.75v9.5A1.75 1.75 0 0 1 14.25 14H8.061l-2.574 2.573A1.458 1.458 0 0 1 3 15.543V14H1.75A1.75 1.75 0 0 1 0 12.25v-9.5C0 1.784.784 1 1.75 1ZM1.5 2.75v9.5c0 .138.112.25.25.25h2a.75.75 0 0 1 .75.75v2.19l2.72-2.72a.749.749 0 0 1 .53-.22h6.5a.25.25 0 0 0 .25-.25v-9.5a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25Z",
      path2:
        "M22.5 8.75a.25.25 0 0 0-.25-.25h-3.5a.75.75 0 0 1 0-1.5h3.5c.966 0 1.75.784 1.75 1.75v9.5A1.75 1.75 0 0 1 22.25 20H21v1.543a1.457 1.457 0 0 1-2.487 1.03L15.939 20H10.75A1.75 1.75 0 0 1 9 18.25v-1.465a.75.75 0 0 1 1.5 0v1.465c0 .138.112.25.25.25h5.5a.75.75 0 0 1 .53.22l2.72 2.72v-2.19a.75.75 0 0 1 .75-.75h2a.25.25 0 0 0 .25-.25v-9.5Z",
    },
  ],

  items: [
    { id: "ai-agents", label: "AI Agents", component: <SectionAgents />, display: true, url: "#ai-agents" },
    { id: "how-it-works", label: "How it works!", component: <SectionHowitWorks />, display: true, url:"#how-it-works" },
    { id: "use-cases", label: "Use Cases", component: <SectionUseCases />, display: true, url:"#use-cases"  },
    { id: "reflection-2024", label: "[ Reflection 2024 ]", component: "", display: false, url:"/reflection2024"  },
    
  ],

  options: [
    {
      id: "setTutorial",
      className: "text-white space-x-2 border-[#6e40c9] border-[1.5px]",
      header: "Book a Demo",
      href: "#",
    },
    {
      id: "signUp",
      className:
        "rt-button-agent-submit border-[#6e40c9] border-[1.5px] text-white hidden lg:block",
      header: "Get started for Free",
      href: "https://studio.ratl.ai",
    },
  ],
  footnotes: [``],
};

const heroData = {
  collabs: [
    {
      src: "/ratl/org-logos/fynd-white-horizontal-text.png",
      alt: "Fynd logo",
      height: 100,
      width: 90,
    },
    {
      src: "/ratl/org-logos/JioMart.png",
      alt: "Jiomart logo",
      height: 100,
      width: 100,
    },
    {
      src: "/ratl/org-logos/tira.png",
      alt: "Tira Beauty logo",
      height: 100,
      width: 75,
    },
    {
      src: "/ratl/org-logos/metro1.png",
      alt: "Metro Cash & Carry logo",
      height: 100,
      width: 100,
    },
    {
      src: "/ratl/org-logos/netmeds1.png",
      alt: "Netmeds logo",
      height: 100,
      width: 170,
    },
    {
      src: "/ratl/org-logos/jiocommerce.png",
      alt: "Jio Commerce logo",
      height: 100,
      width: 120,
    },
    {
      src: "/ratl/org-logos/reliancedigital-light.png",
      alt: "Reliance Digital logo",
      height: 100,
      width: 120,
    },
    
  ],
};

const productivityData = {
  CopilotFeatures: [
    {
      language: "python",
      title: "draw_scatterplot.py",
      icon: <PythonIcon />,
      block: {
        length: 8,
        draw: 1,
      },
    },
    {
      language: "javascript",
      title: "time.js",
      icon: <JavaScriptIcon />,
      block: {
        length: 7,
        draw: 2,
      },
    },
    {
      language: "go",
      title: "memoize.go",
      icon: <GoIcon />,
      block: {
        length: 7,
        draw: 3,
      },
    },
  ],
  IDE: {
    logs: ["Terminal", "Output", "Problems", "Debug Console"],
    terminalOutput: [
      {
        time: "09:43:36",
        action: "Starting",
        extension: "watch-extension:vscode-api-tests",
      },
      {
        time: "09:43:36",
        action: "Finished",
        extension: "clean-extension:typescript-language-features",
        duration: "248 ms",
      },
      {
        time: "09:43:36",
        action: "Starting",
        extension: "watch-extension:typescript-language-features",
      },
      {
        time: "09:43:36",
        action: "Finished",
        extension: "clean-extension:php-language-features",
        duration: "384 ms",
      },
      {
        time: "09:43:36",
        action: "Starting",
        extension: "watch-extension:php-language-features",
      },
      {
        time: "09:43:40",
        action: "Finished",
        extension: "clean-extension:html-language-features-server",
        duration: "4.66 s",
      },
      {
        time: "09:43:40",
        action: "Starting",
        extension: "watch-extension:html-language-features-server",
      },
      {
        time: "09:43:43",
        action: "Finished",
        extension: "clean-client",
        duration: "7.33 s",
      },
      {
        time: "09:43:43",
        action: "Starting",
        extension: "watch-client",
      },
    ],
  },
};

const collaborationData = {
  sponsors: [
    {
      src: "/ratl/ratl-bs.png",
      name: "BrowserStack",
    },
    {
      src: "/ratl/ratl-gatling.png",
      name: "Gatling",
    },
    {
      src: "/ratl/ratl-codium.png",
      name: "Codium",
    },
    {
      src: "/ratl/ratl-lovable.png",
      name: "Lovable.dev",
    },
    {
      src: "/ratl/ratl-replit.png",
      name: "Replit",
    },
    {
      src: "/ratl/ratl-devin.png",
      name: "Devin",
    },
    {
      src: "/ratl/ratl-githubcopilot.png",
      name: "Github CoPilot",
    },
    {
      src: "/ratl/ratl-copilot.png",
      name: "MS CoPilot",
    },
    {
      src: "/ratl/ratl-cursor.png",
      name: "Cursor",
    },
  ],
};

const securityData = {
  blocks: [
    {
      title: "Automated API Testing with Gherkin Scenarios",
      description:
        "Streamline API Validation Using Gherkin and End-to-End Testing",
      href: "/blog/automated-api-testing",
      linkTitle: "Automated API Testing with Gherkin Scenarios",
      className:
        "md:flex flex-col flex-1 p-8 sm:p-10 lg:py-16 lg:px-16 justify-between",
      imgComponent: (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ type: "tween" }}
          className="rounded-r-md overflow-hidden flex items-center"
        >
          <img
            src="/ratl/ratl-mascot-1.png"
            alt="GitHub Sponsors"
            className="w-[30%] h-auto"
          />

          {/* <Picture
            className="responsive js-build-in-item build-in-scale-fade build-in-animate origin-bottom-right"
            size={[80, 80]}
            src="/ratl/ratl-mascot-1.png"
          /> */}
        </motion.div>
      ),
    },
    {
      title: "Delivering Seamless Features with Multi-Agent AI",
      description: "A Real Use Case: The Discount Coupon Feature",
      href: "/blog/delivering-seamless-features",
      linkTitle: "A Real Use Case: The Discount Coupon Feature",
      className:
        "md:flex flex-col flex-1 p-8 sm:p-10 lg:py-16 lg:px-16 justify-between",
      imgComponent: (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ type: "tween" }}
          className="rounded-r-md overflow-hidden flex items-center"
        >
          <img
            src="/ratl/ratl-mascot-2.png"
            alt="GitHub Sponsors"
            className="w-[30%] h-auto"
          />

          {/* <Picture
          className="responsive js-build-in-item build-in-scale-fade build-in-animate origin-bottom-right"
          size={[80, 80]}
          src="/ratl/ratl-mascot-1.png"
        /> */}
        </motion.div>
      ),
    },

    {
      title: "Real-World Use Case: E-Commerce Testing with Autonomous Agents",
      description: "How ShopEase Ensures Seamless Operations with ratl.ai",
      href: "/blog/testing-with-autonomous-agents",
      linkTitle: "How ShopEase Ensures Seamless Operations with Ratl.ai",
      className:
        "md:flex flex-col flex-1 p-8 sm:p-10 lg:py-16 lg:px-16 justify-between",
      imgComponent: (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ type: "tween" }}
          className="rounded-r-md overflow-hidden flex items-center"
        >
          <img
            src="/ratl/ratl-mascot-3.png"
            alt="GitHub Sponsors"
            className="w-[30%] h-auto"
          />

          {/* <Picture
          className="responsive js-build-in-item build-in-scale-fade build-in-animate origin-bottom-right"
          size={[80, 80]}
          src="/ratl/ratl-mascot-1.png"
        /> */}
        </motion.div>
      ),
    },

    {
      title: "API Load Testing with cURL and Performance Tools",
      description: "Evaluating API Performance Under Load with ratl.ai",
      href: "/blog/api-load-testing-guide",
      linkTitle: "Evaluating API Performance Under Load with Ratl.ai",
      className:
        "md:flex flex-col flex-1 p-8 sm:p-10 lg:py-16 lg:px-16 justify-between",
      imgComponent: (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ type: "tween" }}
          className="rounded-r-md overflow-hidden flex items-center"
        >
          <img
            src="/ratl/ratl-mascot-3.png"
            alt="GitHub Sponsors"
            className="w-[30%] h-auto"
          />

          {/* <Picture
          className="responsive js-build-in-item build-in-scale-fade build-in-animate origin-bottom-right"
          size={[80, 80]}
          src="/ratl/ratl-mascot-1.png"
        /> */}
        </motion.div>
      ),
    },
  ],
};

//start testing config start

const startTestingConfigs = {
  dev: {
    apiBaseUrl: "http://localhost:3002",
    uiBaseUrl: "http://localhost:3001", // UI Base URL for dev
  },
  sit: {
    apiBaseUrl: "https://api.ril.ratl.ai",
    uiBaseUrl: "https://ril.ratl.ai", // UI Base URL for sit
  },
  prod: {
    apiBaseUrl: "https://api.studio.ratl.ai",
    uiBaseUrl: "https://studio.ratl.ai", // UI Base URL for prod
  },
};

// Get environment variable or fallback to 'dev'
const ENV = process.env.NEXT_PUBLIC_ENV || "dev";
const validEnvironments = ["dev", "sit", "prod"];
const selectedEnv = validEnvironments.includes(ENV) ? ENV : "dev";
console.log("environment:"+selectedEnv);

// Export the API and UI base URLs
export const API_BASE_URL = startTestingConfigs[selectedEnv].apiBaseUrl;
export const UI_BASE_URL = startTestingConfigs[selectedEnv].uiBaseUrl;

//start testing config end

//pricing data start
const pricingData = {
  metadata: [
    {
      highlight: true,
      badge: "Starter",
      title: "Hobby AI (free)",
      desc: "Perfect for individuals and small teams starting their journey in AI-powered testing",
      price: "$0",
      billing_frequency: "Free forever",
      btn_text: "Get Started for Free",
      btn_href: RT_STUDIO_RIL,
      disclaimer: "No credit cards required - Hop on",
      list_title: "What’s included:",
      features: [
        {
          value: "750 ratl Credits monthly",
        },
        {
          value: "AI-powered API testing",
        },
        {
          value: "AI-driven load and performance testing",
        },
        {
          value: "AI-powered accessibility assessments",
        },
        {
          value: "ratl CoPilot for guided workflows.",
        },
        {
          value: "Alerts via Email & Slack",
        },
        {
          value: "Jira and Azure integration",
        },
        {
          value: "2 users per organization",
        },
        {
          value: "Full access to ratl Studio",
        },
      ],
    },
    {
      highlight: true,
      badge: "Custom",
      title: "Pro AI Agents",
      desc: "Tailored for growing teams, enterprises, and mission-critical projects. Unlock the full power of ratl.ai to meet your unique needs",
      price: "Custom",
      billing_frequency: "Priced for your Org",
      btn_text: "Talk to us",
      btn_href: "#contact-form",
      disclaimer: "Will customise as per your need",
      list_title: "All features in Hobby plan plus:",
      features: [
        {
          value: "Unlimited ratl Credits with no usage limits",
        },
        {
          value:  "Monthly invoicing based on actual usage",
        },
        {
          value: "Powered by AI Agents for intelligent automation",
        },
        {
          value: "Autonomous workflows for hands-free operations",
        },
        {
          value: "AI-powered API authentication handling",
        },
        {
          value: "CI/CD integration for seamless pipeline testing",
        },
        {
          value: "Integration with external AI assistants",
        },
        {
          value: "High-grade security for enterprise applications",
        },
        {
          value: "Run load tests on your cloud for security and flexibility",
        },
        {
          value: "Locally host ratl Studio for full control",
        },
      ],
    },
  ],
};

//pricing data end

//pricing data end
export {
  // layout
  footerData,
  navigationData,

  // sections
  heroData,
  productivityData,
  collaborationData,
  securityData, pricingData,
};
