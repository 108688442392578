import React, { useState } from "react";
import InteractiveCard from "./InteractiveCard";
import { motion } from "framer-motion";
import RenderMeta from "../ratl/utility/RenderMeta";
import CustomButton from "../ratl/utility/CustonButton";
import { useFloat } from "@/lib";
import { RT_STUDIO_RIL } from "@/data";
import SectionTitle from "../SectionTitle";

const validateInputs = (formData: FormData) => {
  const newErrors: Record<string, string> = {};

  const nameRegex = /^[a-zA-Z\s\-]{2,}$/; // For First Name and Last Name
  const minLengthRegex = /^.{2,}$/; // For Company and Job Title

  // Validate First Name
  const firstName = formData.get("firstName")?.toString().trim();
  if (!firstName) {
    newErrors.firstName = "First name is required.";
  } else if (!nameRegex.test(firstName)) {
    newErrors.firstName = "Name must be 2+ letters, spaces, or hyphens";
  }

  // Validate Last Name
  const lastName = formData.get("lastName")?.toString().trim();
  if (!lastName) {
    newErrors.lastName = "Last name is required.";
  } else if (!nameRegex.test(lastName)) {
    newErrors.lastName = "Name must be 2+ letters, spaces, or hyphens";
  }

  // Validate Company
  const company = formData.get("company")?.toString().trim();
  if (!company) {
    newErrors.company = "Company is required.";
  } else if (!minLengthRegex.test(company)) {
    newErrors.company = "Company Name must be 2+ letters";
  }

  // Validate Job Title
  const jobTitle = formData.get("jobTitle")?.toString().trim();
  if (!jobTitle) {
    newErrors.jobTitle = "Job title is required.";
  } else if (!minLengthRegex.test(jobTitle)) {
    newErrors.jobTitle = "Job title must be 2+ letters";
  }

  // Validate Work Email
  const email = formData.get("workEmail")?.toString().trim();
  if (!email) {
    newErrors.workEmail = "Work email is required.";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    newErrors.workEmail = "Invalid email";
  }

  // Validate Phone Number (Optional)
  const phone = formData.get("phoneNumber")?.toString().trim();
  if (phone && !/^\+?\d{7,15}$/.test(phone)) {
    newErrors.phoneNumber = "Invalid phone number";
  }

  return newErrors;
};

const ContactForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const formData = new FormData(e.target as HTMLFormElement);
    const validationErrors = validateInputs(formData);
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    setErrors({});
  
    const payload = {
      firstName: formData.get("firstName")?.toString().trim(),
      lastName: formData.get("lastName")?.toString().trim(),
      company: formData.get("company")?.toString().trim(),
      jobTitle: formData.get("jobTitle")?.toString().trim(),
      phoneNumber: formData.get("phoneNumber")?.toString().trim(),
      workEmail: formData.get("workEmail")?.toString().trim(),
      message: formData.get("message")?.toString().trim(),
    };
  
    const emailPayload = {
      email: payload.workEmail,
      campaign_data: {
        first_name: payload.firstName,
      },
    };
  
    const sendSlackNotification = async () => {
      try {
        const slackResponse = await fetch("/api/slack", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            text: `New Contact Form Submission:
    - *First Name*: ${payload.firstName}
    - *Last Name*: ${payload.lastName}
    - *Company*: ${payload.company}
    - *Job Title*: ${payload.jobTitle}
    - *Phone Number*: ${payload.phoneNumber || "N/A"}
    - *Work Email*: ${payload.workEmail}
    - *Message*: ${payload.message || "N/A"}`,
          }),
        });
  
        if (!slackResponse.ok) {
          console.error("Slack Notification Failed:", await slackResponse.text());
        } else {
          console.log("Slack notification sent successfully");
        }
      } catch (error) {
        console.error("Error sending Slack notification:", error);
      }
    };
  
    const sendEmail = async () => {
      try {
        const emailResponse = await fetch("/api/mailmodo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailPayload),
        });
  
        if (!emailResponse.ok) {
          console.error("Mailmodo Email Failed:", await emailResponse.text());
        } else {
          console.log("Mailmodo email sent successfully");
        }
      } catch (error) {
        console.error("Error sending Mailmodo email:", error);
      }
    };
  
    // Call both APIs independently
    sendSlackNotification();
    sendEmail();
  
    // Always show the thank-you message
    setIsSubmitted(true);
  };
  

  const { float, handleFloat } = useFloat([false, false]);

  return (
    <>
    <SectionTitle titleHighlight={"Contact Us"} titleDesc="See what best fits for you" showIcon={false} sectionColor="red" />

      <div className="w-full" id="contact-form">
        <InteractiveCard
          backgroundColor="#FFA28B"
          direction="flex-col"
          left="-400px"
        >
          <div className="flex justify-center items-center">
            {isSubmitted ? (
              <div className="text-center p-6 lg:p-14">
                <h3 className="text-2xl font-normal text-white mb-4">
                  Thank you for your interest!
                </h3>
                <p className="text-gray-400 lg:mb-6">
                  One of our team members will reach out to you shortly!
                </p>
                <CustomButton
                  text={"Get Started for Free"}
                  animationRequired={true}
                  bgType={"solid"}
                  onMouseEnter={() => handleFloat(true, 1)}
                  onMouseLeave={() => handleFloat(false, 1)}
                  controller={float[1]}
                  onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
                  btnSize="small"
                  isStudioBtn={true}
                />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg rounded-2xl px-8 pt-6 pb-8 md:my-14 shadow-lg border border-rt-red-p1"
              >
                <h2 className="text-2xl font-medium text-center mb-6 text-white">
                  Contact us
                </h2>
                {[
                  { id: "firstName", label: "First Name (Required)" },
                  { id: "lastName", label: "Last Name (Required)" },
                  { id: "company", label: "Company (Required)" },
                  { id: "jobTitle", label: "Job Title (Required)" },
                  {
                    id: "workEmail",
                    label: "Work Email (Required)",
                    type: "email",
                  },
                  {
                    id: "phoneNumber",
                    label: "Phone Number (Optional)",
                    type: "tel",
                  },
                ].map((field) => (
                  <div key={field.id} className="mb-4">
                    <label
                      htmlFor={field.id}
                      className="hidden text-gray-300 text-sm font-normal mb-2"
                    >
                      {field.label}
                    </label>
                    <input
                      className="appearance-none border border-gray-500 rounded w-full py-2 px-3 bg-gray-900 text-gray-300 placeholder-gray-500 text-sm leading-tight focus:outline-none focus:border-rt-purple-p1"
                      id={field.id}
                      name={field.id}
                      type={field.type || "text"}
                      placeholder={field.label}
                      required={!field.label.includes("(Optional)")}
                    />
                    {errors[field.id] && (
                      <p className="text-rt-red-p1 text-xs mt-1">
                        {errors[field.id]}
                      </p>
                    )}
                  </div>
                ))}
                <div className="mb-6">
                  <label
                    htmlFor="message"
                    className="hidden text-gray-300 text-sm font-normal mb-2"
                  >
                    Message (Optional)
                  </label>
                  <textarea
                    className="appearance-none border border-gray-500 rounded w-full py-2 px-3 bg-gray-900 text-gray-300 placeholder-gray-500 text-sm leading-tight focus:outline-none focus:border-rt-purple-p1"
                    id="message"
                    name="message"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    className=" text-white font-normal border border-rt-red-p1 py-2 px-4 rounded focus:outline-none"
                    type="submit"
                  >
                    Contact
                  </button>
                </div>
              </form>
            )}
          </div>
        </InteractiveCard>
      </div>
    </>
  );
};

export default ContactForm;
