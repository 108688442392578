export interface TextProps {
    stepLabel: string;
    stepTitle: string;
    stepDescription: string;
    h2Text: string;
  }
  
  export interface ImageProps {
    bgStars: string;
    stepImage: string;
  }
  
  export interface BranchData {
    text: TextProps;
    images: ImageProps;
  }
  
  export const BRANCH_DATA: BranchData[] = [
    {
      text: {
        stepLabel: "Getting Started",
        stepTitle: "Step 1",
        stepDescription: "Upload or integrate API artifact",
        h2Text: "lets you connect your preferred API documentation platform or upload a cURL to get started. With just a one-time setup, it seamlessly tracks and manages your artifacts, ensuring effortless updates and consistent testing workflows",
      },
      images: {
        bgStars: "/home-campaign/bg-stars-1.webp",
        stepImage: "/ratl/steps/ratl-step-getting-started.png",
      },
    },


    {
      text: {
        stepLabel: "Execution",
        stepTitle: "Step 2",
        stepDescription: "Choose either Autonomous or Manual Execution",
        h2Text: "empowers users with AI agents for autonomous API testing and AI-enhanced UI testing. Effortlessly create test cases, generate code, and execute tests—all within minutes, eliminating unnecessary intermediate steps",
      },
      images: {
        bgStars: "/home-campaign/bg-stars-1.webp",
        stepImage: "/ratl/steps/ratl-step-execution.png",
      },
    },
    {
      text: {
        stepLabel: "Integration",
        stepTitle: "Step 3",
        stepDescription: "Integrate Your Favorite Tools",
        h2Text: "integrates effortlessly with tools like Postman, Swagger, Slack, Jira, Azure, and CI/CD pipelines. Enhance your workflows with AI-driven automation while staying connected to the platforms you trust for seamless collaboration and efficiency",
      },
      images: {
        bgStars: "/home-campaign/bg-stars-1.webp",
        stepImage: "/ratl/steps/ratl-step-integration.png",
      },
    },
  ];
  