"use client";
import { StickyNavbar } from "@/components/layout";
import { Globe } from "@/components/widgets";
import Hero from "@/components/sections/Hero";
import HeroBanner from "@/components/HeroBanner"
import { navigationData } from "@/data";
import { v4 as uuidv4 } from "uuid";


/* eslint-disable @next/next/no-img-element */
export default function Home() {
  return (
    <div className="overflow-hidden">
      <HeroBanner />
      <Hero />
      <StickyNavbar />
      {navigationData.items.map((section, index) => (
        <div
          key={uuidv4()}
          id={section.id}
        >
          {section.display? section.component:""}
        </div>
      ))}
      <Globe />
    </div>
  );
}


