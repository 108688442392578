"use client";

/* eslint-disable @next/next/no-img-element */
import { v4 as uuidv4 } from "uuid";
import { footerData} from "@/data";

const Footer = () => {
  const {items, socials, ratlfooterlinks } = footerData;

  return (
    <footer className="footer relative lg:pt-14 pt-0 break-words">
      <div className="max-w-[1280px] mx-auto relative z-[2] overflow-hidden">
        
        <div className="flex flex-col items-center lg:flex-row py-10 mb-8 px-4  justify-center">
          <div className="mb-12 px-4">
            <a
              href="#"
              className="color-fg-default d-inline-block"
              aria-label="Go to ratl.ai homepage"
            >
              <img
                src="/ratl/ratl-logo-full-white.png"
                alt="ratl.ai Logo"
                className="w-32 h-auto"
              />
            </a>
          </div>

          
          <div className="lg:hidden">

                <ul className="text-[13px] flex flex-row justify-center items-center gap-4">
                  {ratlfooterlinks.map((item) => (
                    <a
                      href={item.href}
                      key={item.title}
                      className=" text-[#7d8590]"
                    >
                      <li className="mb-4">{item.title}</li>
                    </a>
                  ))}
                </ul>
          </div>


        </div>
      </div>
      
      
      <div className="bg-[#161b22]">
        <div className="lg:max-w-[1280px] w-full mx-auto text-[12px] flex flex-col md:flex lg:flex lg:flexx-row lg:flex-row-reverse py-6 justify-center lg:justify-between items-center px-4">
          <ul className="flex items-center max-lg:mb-4">
            {socials.map((url) => (
              <li className="mr-4" key={uuidv4()}>
                <a href={url.href} target="_blank">
                  {url.icon}
                </a>
              </li>
            ))}
          </ul>
          <ul className="flex items-center mb-4 sm:mb-0 text-[#7d8590] flex-wrap">
            <li className="mr-3">&copy; 2024 ratl.ai - Fynd</li>
            {items.map((item) => (
              <li className="mr-3" key={uuidv4()}>
                <a
                  href={item.href}
                  className="transition ease-in duration-100 hover:underline"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
