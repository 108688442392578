import { useState } from "react";
import YouTubeEmbed from "../ratl/utility/YouTubeEmbed";
import { RT_INTRO_VIDEO_CDN, RT_INTRO_VIDEO_YT } from "@/data";

const VideoBox = () => {
  const [isError, setIsError] = useState(false);

  return (
    <div className="aspect-w-16 aspect-h-9 rounded-xl border-[1.5px] border-rt-green-p1 overflow-hidden relative shadow-2xl bg-[#161b22]">
      {!isError ? (
        <video
          className="w-full h-full object-cover"
          controls
          onError={() => setIsError(true)} // Fallback to YouTube on error
          poster="/ratl/ratl-video-cover.webp" // Replace with your cover image URL
        >
          <source src={RT_INTRO_VIDEO_CDN} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="w-full">
          <YouTubeEmbed videoId={RT_INTRO_VIDEO_YT} />
        </div>
      )}
    </div>
  );
};

export default VideoBox;
