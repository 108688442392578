"use client";
import { RT_HERO_BANNER } from "@/data";
import React from "react";

type Props = {};

const HeroBanner = (props: Props) => {
  return (
    <div className="relative">
      <img
        className="absolute top-0 transition ease-in duration-200 max-xl:right-[-1450px] xl:right-[-1470px] -z-30 image"
        width="4377"
        src={RT_HERO_BANNER}
        alt="Hero Background"
      />
    </div>
  );
};

export default HeroBanner;
