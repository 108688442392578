import React from 'react';

type SectionFrameProps = {
  children: React.ReactNode;
  className?: string; // Optional prop for custom class name
};

const SectionFrame: React.FC<SectionFrameProps> = ({ children, className }) => {
  const baseClassName = "home-section-undefined"; // Define the base class name here
  return (
    <div className={`${baseClassName} ${className || ''} px-4 py-3`}>
        <div className="max-w-[1280px] mx-auto">
      {children}
      </div>
    </div>
  );
};

export default SectionFrame;
