import { useFloat } from "@/lib";
import CustomButton from "./CustonButton";
import { CiCalendar } from "react-icons/ci";
import { RT_DEMO_CAL_URL, RT_STUDIO_RIL } from "@/data";

const CtaButtons = () => {
  const { float, handleFloat } = useFloat([false, false]);
  return (
    <div className="flex max-lg:flex-row  lg:gap-4 max-md:flex-col gap-4">
              <CustomButton
                text={"Have Questions?"}
                animationRequired={true}
                bgType={"transparent"}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
                onClick={() => (window.location.href = "/pricing#contact-form")}
                btnSize="small"
                isStudioBtn={false}
                showMobile={true}
              />

              <CustomButton
                text={"Get Started for Free"}
                animationRequired={false}
                bgType={"solid"}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
                image="/ratl/ratl-mascot-3.png"
                onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
                btnSize="small"
                isStudioBtn={true}
                showMobile={false}

              />
            </div>
  );
};

export default CtaButtons;
