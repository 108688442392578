"use client";

import { useEffect } from "react";
import clarity from "@microsoft/clarity";

const ClarityIntegration = () => {
  useEffect(() => {
    const clarityProjectId = process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID;
    if (clarityProjectId) {
      clarity.init(clarityProjectId);
    } else {
      console.log("Clarity Project ID is not set in environment variables.");
    }
  }, []);

  return null; // This component does not render anything
};

export default ClarityIntegration;
