import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { generateContainer, useFloat } from "@/lib";

import { CopilotBlock, AnimatedAnchor, Picture } from "@/components/atoms";
import { InteractiveCard } from "@/components/widgets";

import { ReplayIcon } from "@/assets/icons";

import { productivityData } from "@/data";

const Copilot = () => {
  const { float, handleFloat } = useFloat(false);

  const [count, setCount] = useState(0);
  const [activeLanguage, setActiveLanguage] = useState<string>("python");

  const handleLanguageChange = (language: string) => {
    setActiveLanguage(language);
  };

  const container = generateContainer(1, 0.05, 0.1);

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { type: "tween" } },
  };

  return (
    <InteractiveCard backgroundColor="#7ee787" direction="" left="0">
      <div className="md:flex flex-col md:space-y-20 flex-1 p-8 sm:p-10 lg:py-16 lg:pl-16 lg:pr-32 justify-between">
        <p className="rt-para mb-6 text-[#7d8590]">
          <span className="text-white font-medium">AI agents</span> in autonomous API testing free developers from manual testing, enabling them to focus on building features. They quickly identify issues, streamline debugging, and ensure seamless integration into CI/CD pipelines
        </p>
        {/* <div>
          <AnimatedAnchor
            onMouseEnter={() => handleFloat(true)}
            onMouseLeave={() => handleFloat(false)}
            className="md:text-xl text-white font-medium inline-block"
            title="Try with Sample cURL"
            controller={float}
            isUnderline
            
          />
        </div> */}
      </div>
      <div className="overflow-hidden rounded-s-lg z-[1] flex-1 shadow-3xl">
        <div className="text-left border-[0.5px] bg-[#161b22] border-[#30363d] rounded-lg text-[#161b22] box-shadow-card-mktg md:mt-10 md:ml-0 sm:ml-10 sm:mr-10 mb-16 ml-3 mr-3 lg:my-16 lg:mr-16">
        <Picture
            src="/ratl/ratl-agents-flow.png"
            size={[746, 368]}
            className="width-full d-block h-auto" alt={""}                  />
        </div>
      </div>
    </InteractiveCard>
  );
};

export default Copilot;
