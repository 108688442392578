import { color } from 'framer-motion';
import React from 'react';
import { IconType } from 'react-icons';

type IconProps = {
  Icon: IconType; // Icon component from react-icons
  className?: string; // Optional Tailwind CSS classes for styling
  size?: number | string; // Optional size for the icon
  iconcColor?: string;
};

const ReactIcon: React.FC<IconProps> = ({ Icon, className='my-1', size=22, iconcColor='white' }) => {
   
  return <Icon className={className} size={size} color={iconcColor} />;
};

export default ReactIcon;
