/* eslint-disable @next/next/no-img-element */
import { v4 as uuidv4 } from "uuid";
import { useFloat } from "@/lib";
import { heroData, RT_HERO_TITLE_L1, RT_STUDIO_RIL } from "@/data";

import { useEffect } from "react";
import GlowingIconBg from "../ratl/display/GlowingIconBg";
import CustomButton from "../ratl/utility/CustonButton";

import Picture from "../ratl/utility/Picture";
import StartTesting from "./StartTesting";
import Image from "next/image";
import { motion } from "framer-motion";

const Hero = () => {
  const { float, handleFloat } = useFloat([false, false]);
  useEffect(() => {
    const basePhrase = "Enter";
    const dynamicPhrases = [
      "cURL command",
      "Web URL",
      "Postman Collection URL",
    ];
    let i = 0;
    let j = 0;
    let currentPhrase = "";
    let isDeleting = false;
    const input = document.getElementById("typewriter-input");

    function type() {
      if (input) {
        if (!isDeleting && j <= dynamicPhrases[i].length) {
          currentPhrase = basePhrase + " " + dynamicPhrases[i].substring(0, j);
          input.setAttribute("placeholder", currentPhrase);
          j++;

          if (j === dynamicPhrases[i].length + 1) {
            setTimeout(() => {
              isDeleting = true;
              type();
            }, 1000); // Pause after completing the phrase
            return;
          }
        }

        if (isDeleting && j >= 0) {
          currentPhrase = basePhrase + " " + dynamicPhrases[i].substring(0, j);
          input.setAttribute("placeholder", currentPhrase);
          j--;
        }

        if (isDeleting && j === 0) {
          isDeleting = false;
          i++;
        }

        if (i === dynamicPhrases.length) {
          i = 0;
        }

        setTimeout(type, isDeleting ? 50 : 100);
      }
    }

    type();
  }, []);
  return (
    <section id="hero-home-page" className="px-3">
      <div className="relative max-w-[1280px] lg:pt-20 md:px-10 mx-auto pt-16">
        <Picture
          className="absolute hero-mascot top-28 w-1/4 h-auto right-0 xl:right-0"
          size={[500, 326]}
          src="/ratl/ratl-mascot-spaceship.png"
          alt={"Ratl mascot flying in a spaceship"}
        />

        <div className="flex">
          {/* left gradient line */}
          <div className="relative">
            <Picture
              size={[437, 637]}
              src="/home-campaign/lines-hero.svg"
              className=""
              alt={"Graphical lines"}
            />

            <div className="mx-auto my-3">
              <span className="relative z-[11]">
                <Picture
                  size={[24]}
                  src={"/ratl/ratl-mascot-2.png"}
                  alt={"ratl mascot section for text area"}
                />
                {/* <GlowingIconBg /> */}
              </span>
            </div>

            {/* <div
              style={{
                background:
                  "linear-gradient(#d2a8ff, #a371f7 10%, #196c2e 70%, #2ea043 80%, #56d364)",
                marginLeft: "11px",
              }}
              className="max-md:w-[2px] w-[3px] lg:h-[450px] md:h-[650px] max-sm:h-[300px] line rounded-md"
            /> */}
          </div>

          {/* hero body */}
          <div className="absolute pt-32 lg:mt-40 mt-32 max-md:px-4 ml-4 md:ml-12">
            <h1 className="md:mt-28 lg:mt-0 sm:mt-12 relative z-2 max-md:mb-5 rt-font-hero-title">
              {RT_HERO_TITLE_L1}
            </h1>

            <StartTesting />

            {/* <p className="hidden relative z-1 text-sm md:text-[28px]  lg:text-[32px] leading-5 md:leading-[36px] lg:leading-[44px] mb-5 md:mb-12 md:10/12  lg:w-9/12 text-[#7d8590]">
              API Functional, Integration, and Load Testing, all completed in
              just a few minutes!
            </p> */}

            {/* <div className="flex max-lg:flex-row lg:w-2/3 lg:gap-4 max-md:flex-col gap-4">
              <CustomButton
                text={"Talk to Us"}
                animationRequired={true}
                bgType={"transparent"}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
        
                onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
                btnSize="large"
                isStudioBtn={false}
                showMobile={true}
              />

              <CustomButton
                text={"Get Started for Free"}
                animationRequired={false}
                bgType={"solid"}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
                image="/ratl/ratl-mascot-3.png"
                onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
                btnSize="large"
                isStudioBtn={true}
                showMobile={false}
              />
            </div> */}

            <div className="md:my-14 my-10 lg:my-14">
              <p className="rt-para text-[#7d8590]">
                Trusted by the world's leading organizations
              </p>

              <div className="flex flex-wrap gap-4 md:gap-4 lg:gap-10 items-center mt-4">
              
                {heroData.collabs.map((logo) => (
                  <Image
                    key={logo.src} // Add a key for better React rendering
                    src={logo.src}
                    alt={logo.alt}
                    width={logo.width}
                    height={logo.height}
                    className="max-w-[120px] sm:max-w-[150px] md:max-w-[200px] object-contain mt-3 md:mt-0" // Adjust sizes based on screen
                  />
                ))}
              </div>
            </div>

            {/* <div className="mt-6 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 items-center gap-y-4 gap-x-4">
                {heroData.collabs.map((logo) => (
                  <img
                    key={uuidv4()}
                    src={logo.src}
                    alt={logo.alt}
                    width={logo.width}
                    height={logo.height}
                  />
                ))}
              </div> */}
          </div>
        </div>
      {/* ending drop line */}
      
      <motion.div
        initial={{ height: "150px" }}
        whileInView={{ height: "260px" }}
        transition={{ delay: 0.3 }}
        viewport={{ once: false }}
        className="hidden md:hidden lg:flex ml-3 w-[3px] rounded-md bg-gradient-to-b from-rt-purple-p1 via-rt-purple-p2 to-rt-purple-p1"
      ></motion.div>
      <motion.div
        initial={{ height: 0 }}
        whileInView={{ height: "160px" }}
        transition={{ delay: 0.3 }}
        viewport={{ once: false }}
        className="hidden md:flex lg:hidden ml-3 mt-[-20px] w-[3px] rounded-md bg-gradient-to-b from-transparent via-rt-purple-p2 to-rt-purple-p1"
      ></motion.div>
      <motion.div
        initial={{ height: 0 }}
        whileInView={{ height: "260px" }}
        transition={{ delay: 0.3 }}
        viewport={{ once: false }}
        className="lg:hidden md:hidden ml-3 mt-[-20px] w-[3px] rounded-md bg-gradient-to-b from-transparent via-rt-purple-p2 to-rt-purple-p1"
      ></motion.div>
      </div>
      
    </section>
  );
};

export default Hero;
