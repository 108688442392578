"use client"
import { Controllerable, Mouseable, Styleable } from "@/@types";
import { ArrowIcon } from "@/assets/icons";
import { AnimatedUnderline } from "@/components/atoms";
import React from "react";

type ButtonProps = {
  isStudioBtn: boolean;
  btnSize: "small" | "large";
  image?: string;
  icon?: JSX.Element;
  text: string;
  animationRequired: boolean;
  bgType: "solid" | "transparent";
  onClick?: () => void;
  href?: string;
  isUnderline?: boolean;
  isBlack?: boolean;
  iconSize?: number;
  showMobile?:boolean
} & Styleable &
  Pick<Controllerable, "controller"> &
  Mouseable;

const CustomButton: React.FC<ButtonProps> = ({
  isStudioBtn,
  btnSize,
  image,
  icon,
  text,
  animationRequired,
  bgType,
  onClick,
  href,
  controller,
  onMouseEnter,
  onMouseLeave,
  isUnderline = false,
  isBlack = false,
  iconSize = 20,
  showMobile,
}) => {
  // Background type class
  const bgClass =
    bgType === "solid" ? "rt-button-solid-bg" : "rt-button-transparent-bg";

  const buttonSize = 
    btnSize === "small" ? "px-4 py-2 text-sm" : "py-3 px-4 text-base"; 

  const StudioLinkDisable = 
    isStudioBtn? "hidden lg:flex":"";


  const btnVisibility = showMobile? "" :"hidden lg:flex"; 

  return (
    <>
      <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type="button"
        onClick={onClick}
        className={`rt-button flex flex-row justify-center items-center gap-4 w-auto whitespace-nowrap ${bgClass} ${buttonSize} ${btnVisibility}`}
      >
        {image && <img src={image} alt="Button Image" className="w-8 h-8" />}
        {icon && <span>{icon}</span>}
        <span>{text}</span>

        {animationRequired ? (
          <>
            <ArrowIcon
              controller={controller}
              size={iconSize}
              isBlack={isBlack}
            />
            {isUnderline && <AnimatedUnderline controller={controller} />}
          </>
        ) : (
          ""
        )}
      </button>
    </>
  );
};

export default CustomButton;
